import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { getEventsPage, getEvents } from "../../providers"

import { Hero4 } from "../../components/Hero4"
import { MainLayout } from "../../components/Layouts/MainLayout"
import OurEvents from "../../components/OurEvents/OurEvents"

import "../../assets/css/global.css"

const Container = styled.div`
  width: 100%;
  height: 100%;
`
const Event = () => {
  const [eventsPage, setEventsPage] = useState({})
  const [events, setEvents] = useState([])

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const _eventsPage = await getEventsPage()
        setEventsPage(_eventsPage[0])
        const _events = await getEvents()
        setEvents(_events)
      

      } catch (error) {
        console.log(error)
      }
    }
    fetchEvents()
  }, [])

  return (
    <MainLayout>
      <Hero4
        backgroundImage={eventsPage?.background_image}
        title={eventsPage?.title}
      />
      <Container>
        <OurEvents
          data={events}
          description={eventsPage?.description}
        />
      </Container>
    </MainLayout>
  )
}

export default Event
