import React from "react"
import styled from "styled-components"
import OurEvents from "./OurEvents"
import { Button03 } from "../Button03"
import { Link } from "gatsby"

const Container = styled.div`
  width: 100%;
  //height: 380px;
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
   
  @media (max-width: 900px) {
    position: relative;
    width: 90%;
    left: 5%;
    display: flex;
    height: 100%;
    flex-direction: column;

    /*.center {
      display: flex;
      justify-content: center;
      margin-top: 4em;
      margin-bottom: 15px;
    }*/
  }

`

const Containertext = styled.div`
position: relative;
padding: 16px 10px 0px;
width: 65%;
border-left: 4px solid #0044B0;
padding-left: 30px;
margin-left: 30px;

.h1{
  font-weight: bold;
  font-size: 24px;
}
.locationStyle{
  font-weight: bold;
  font-size: 19px;
}
.dateStyle{
  font-weight: normal;
  font-size: 16px;
  color:#0044B0;
}
.text {
      line-height: 150%;
      color: #000000;
      font-size: 15px;
      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      line-clamp: 8;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
    }


@media (max-width: 900px) {
  width:100%;
  .h1{
    font-size: 22px;
  }
  .locationStyle{
    font-size: 16px;
  }
  .text {

      font-size: 16px;
      line-height: 160%;
      color: #000000;
    }
    .title {
 
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 8px;
    }
}
@media (max-width: 500px) {
  padding-left: 20px;
  margin-left: 20px;

  .h1{
    font-size: 16px;
  }
  .locationStyle{
    font-size: 15px;
  }
  .dateStyle{ 
    font-size: 13px}
  .text {
    font-size: 13px
  }

}
`

const Containerimage = styled.div`
position: relative;
display: flex;
justify-content: center;
width: 380px;
height: 350px;

object{
 
  border-radius: 30px;
  object-fit: cover;
}


@media (max-width: 500px) {
  height: auto;

}
`

const EventCards = ({ data}) => {
 
  const paragraphs = data.description.split("/").map((paragraph, index) => (
    <p key={index}>{paragraph}</p>
  ));


  return (
    <Container>

      <Containerimage>
        <object width="90%" data={data.image} />
      </Containerimage>

      <Containertext>
      <div className="h1">{data.title}</div>
        <div className="locationStyle">{data.location}</div>
        <div className="dateStyle">{data.date}</div>
        <div className="text">{data.description &&paragraphs}</div>
       {/* <div className="text">{data.description}</div>
         <div className="text">
          Instructor: {data.instructor.name} {data.instructor.lastName}
        </div>
       <div className="text">Código ID: {data.instructor.code}</div>
         <div className="text center">{data.time}</div> */}
        <br/>
        <div>
                <Link to={data.button_text_link || `/evento/${data.id}`} target="_blank">
                <Button03 className="resizebutton" type="small">{data.button_text || 'Ver evento'}</Button03>
                </Link>
         </div>
      </Containertext>
    </Container>
  )
}

export default EventCards

