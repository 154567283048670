import React, { useState } from "react"
import styled from "styled-components"
import { Pagination } from "antd"

import { Title } from "../Title"
import EventCards from "./EventCards"
import VideoDescription from "./VideoDescription"

const Container = styled.div`
  width: 100%;
  max-width: 80rem;
  margin: 4.125rem auto 0;
  padding: 0 3.125em;
  margin-top: 4.25em;
  .ant-pagination {
    margin: 30px 0;
    display: flex;
    justify-content: center;

  }

  .pagination .ant-pagination-item:hover,
.pagination .ant-pagination-item a:hover, .pagination .ant-pagination-item-link:hover {
  border-color: #0044B0; /* Cambia el color del borde al pasar el ratón */
  color: #0044B0; /* Cambia el color del texto al pasar el ratón */
}

.pagination .ant-pagination-item-active,
.pagination .ant-pagination-item-active a {
  border-color: #0044B0; /* Cambia el color del borde al pasar el ratón */
  color: #0044B0; /* Cambia el color del texto al pasar el ratón */
}
`
const FirstBlock=styled.div`
  position: relative;
  width: 100%;
  display:flex;
  justify-content: center;
  flex-direction: column;
  
`
const FirstBlockChild= styled.div`
max-width: 1440px;
width: 100%;
position: relative;
display:flex;
flex-direction: row;
align-content:baseline;
justify-self: center;

.icon {
align-self: center;
width: 45px;
}
@media (max-width: 45px) {
}

.placetitle{
align-content :baseline ;
flex-wrap: nowrap;
width: auto;

}

`
const SecondBlockChild=styled.div`
width: 100%;
display: flex;
justify-content: center;
margin-top: 1em;
flex-direction: column;
padding: 0 0 0 2.5em;
p{
  margin: 0;
  font-size: 20px;
}
@media (max-width:500px) {
  padding: 0;
  text-align: justify;
  p{
    font-size: 15px;
  }
}
`

const ContentCardsC = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex:1 1;
  @media (max-width: 900px) {
    justify-content: center;
  }
`

const ContentCards = styled.div`
  display: flex;
  padding: 0;
  margin: 40px 0 0;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 1200px) {
    justify-content: center;
    flex-direction: column;
  }
`


const MainContainer = styled.div`
display: flex;
gap:20px;
@media (max-width: 900px) {
    flex-direction: column;
    gap:6px;
  }

`

const OurEvents = ({ data,description}) => {
  const [minValue, setMinValue] = useState(0)
  const [maxValue, setMaxValue] = useState(4)

  const handleChange = value => {
    if (value <= 1) {
      setMinValue(0)
      setMaxValue(4)
    } else {
      setMinValue(maxValue)
      setMaxValue(value * 4)
    }
  }

  const paragraphs = description ? description.split("/") : [];

  const decreasing = data.slice()

  .sort((a, b) =>(b.id) - (a.id));

  return (
    <Container>
      <FirstBlock>
          <FirstBlockChild>
          <div className="icon"><img src="\images\viñeta.svg"/></div>
          <div className="placetitle"><Title>Eventos y actividades</Title></div>
          </FirstBlockChild>

          <SecondBlockChild>
                    <div>{paragraphs.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                    ))}</div>
          </SecondBlockChild>
      </FirstBlock>

      <ContentCards>
        
      <ContentCardsC>
          {data &&
            decreasing
              .slice(minValue, maxValue)
              .map(val => 
                <MainContainer>
              <EventCards data={val} key={val.id}></EventCards>
                {/*<VideoDescription
                description={val}
                key={val.id}
              ></VideoDescription>*/}
              </MainContainer>
              
              )}
        </ContentCardsC>
        
        {/*<ContentCardsC>
          {data &&
            data
              .slice(minValue, maxValue)
              .map(val => (
                <VideoDescription
                  description={val}
                  key={val.id}
                ></VideoDescription>
              ))}
        </ContentCardsC>*/}
       
      </ContentCards>
      {/*<ContentCards>
        {data &&
          data
            .slice(minValue, maxValue)
            .map(val => (
              <VideoDescription
                description={val}
                key={val.id}
              ></VideoDescription>
            ))}
      </ContentCards>
      <ContentCards>
        {data &&
          data
            .slice(minValue, maxValue)
            .map(val => <EventCards data={val} key={val.id}></EventCards>)}
        </ContentCards>*/}
      <Pagination
        className="pagination"
        defaultCurrent={1}
        defaultPageSize={4}
        onChange={handleChange}
        total={data.length}
        hideOnSinglePage={false}
      ></Pagination>
    </Container>
  )
}

export default OurEvents
