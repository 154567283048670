import React from "react"
import styled from "styled-components"

const Container = styled.div`
  width: 575px;
  height: 295px;
  margin-bottom: 40px;
  box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-wrap: wrap;
  .content-info {
    width: 100%;
  }
  .content-info {
    padding: 16px 18px 0px;
    div {
      width: 100%;
      display: flex;
      margin-bottom: 10px;
    }
    .title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
    }
    .text {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 160%;
      color: #000000;
    }
    .center {
      display: flex;
      justify-content: center;
    }
  }
  @media (max-width: 900px) {

    position: relative;
    width: 90%;
    left: 5%;
    height: 100%;
    padding: 10px 10px 0px;
    display: flex;
    .content-info {
      width: 100%;
    }

    .title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      margin-bottom:16px;
    }
  }
`



const VideoDescription = ({ description }) => {

  return (
    <Container>
      <div className="content-info">
        {/*<div className="title blue">{description.title}</div>*/}
        <div className="text">{description.text}</div>
        </div>
    </Container>
  )
}

export default VideoDescription
